const nvQuiz = {
  init: function (formId) {
    const theForm = document.getElementById(formId);

    this.validateOnSubmit(theForm);
  },

  validateOnSubmit: function (theForm) {
    if (!theForm) return false;

    const submitBtn = theForm.querySelector('a[type="submit"]');

    submitBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const isFormValid = this.checkValidity(theForm);
      console.log(isFormValid);

      if (isFormValid) {
        const pid = this.checkValidity(theForm);
        const btn = submitBtn.closest('.modal-footer .cta-btn');
        btn.setAttribute('data-pid', pid);
        btn.click();
      }
    });
  },

  checkValidity: function (theForm) {
    if (!theForm) return false;
    const select = theForm.querySelector('select');
    if (select.value === '') {
      select.classList.add('is-invalid');
      return false;
    } else if (select.value < 3) {
      return '6c3b2cb4-2965-4127-92b5-d077edfe07ca';
    } else {
      return 'a0e1e8d9-20b2-4459-a8c4-5248acb9db77';
    }
  },
};
